import React from "react";
import { Grid, Box, Typography, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import "normalize.css/normalize.css";
import "../styles/styles.scss";
import VinForm from "../components/VinForm";
import CarLookUpForm from "../components/CarLookUpForm";

const useStyles = makeStyles({
  stepper: {
    padding: "40px",
  },
  container: {
    padding: "40px",
  },
  media: {
    height: "256px",
  },
});

const GetMarketData = () => {
  const classes = useStyles();

  //for tabs
  const [value, setValue] = useState(0);
  const [turnOffButtons, setTurnOffButtons] = useState({ value: 0 });
  const [title, setTitle] = useState({
    text: "Submit your offer and get paid!",
  });
  const [subtitle, setSubTitle] = useState({
    text:
      "We've designed a simple process for people that want to sell their car now!",
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Box bgcolor="background.paper" className={classes.container}>
        <Grid container direction="column" justifyContent="center">
          <Grid item xs={12} md={12}>
            <Typography variant="h4" component="h2" gutterBottom={true}>
              {turnOffButtons.value === 1
                ? "Estimated Value"
                : "Get estimated cash value!"}
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph={true}>
              {turnOffButtons.value === 1
                ? ""
                : "We've designed a simple process to sell your car now!"}
            </Typography>
            <Grid item xs={12} md={12}>
              {turnOffButtons.value === 0 && (
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  color="secondary"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                  style={{ padding: "10px" }}
                >
                  <Tab label="Active" label="Look up by Vin" />
                  <Tab label="Active" label="Look up by Make & Model" />
                </Tabs>
              )}
              {value === 0 && <VinForm setTurnOffButtons={setTurnOffButtons} />}
              {value === 1 && (
                <CarLookUpForm setTurnOffButtons={setTurnOffButtons} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default GetMarketData;
