import React from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useForm, Form } from "./useForm";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiPhoneNumber from "material-ui-phone-number";
import emailjs from "emailjs-com";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    maxWidth: 660,
  },
  button: {
    height: "60px",
    width: "100%",
  },
}));

export default function ReviewOffer(props) {
  const classes = useStyles();
  const { values, setValues, handleInputChange } = useForm(initialValues);
  const [showForm, setShowForm] = useState({ value: 1 });

  function sendEmail(e) {
    e.preventDefault();

    const setForm = {
      ...props,
      values,
    };

    const data = {
      service_id: "service_hry0o0b",
      template_id: "template_6o6gln9",
      user_id: "user_YhQYYQ2UvkWjkcnCly51f",
      template_params: setForm,
    };

    fetch("https://api.emailjs.com/api/v1.0/email/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    }).then(
      (result) => {
        // console.log("success" + result.text);
        setValues(initialValues);
        setShowForm({ value: 0 });
        // props.setShowForm({ value: 0 });
      },
      (error) => {
        console.log("Error" + error.text);
      }
    );
    e.target.reset();
  }

  return (
    <div>
      {showForm.value === 1 && (
        <Form submit={sendEmail}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <h3 style={{ padding: "8px" }}>
                We just need a few more details before we can submit your
                offer...
              </h3>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="First Name"
                name="firstName"
                value={values.firstName}
                onChange={handleInputChange}
                autoFocus
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="Last Name"
                name="lastName"
                value={values.lastName}
                onChange={handleInputChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="Email"
                name="email"
                type="email"
                value={values.email}
                onChange={handleInputChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <MuiPhoneNumber
              name="phone"
              label="Phone Number"
              data-cy="user-phone"
              defaultCountry={"us"}
              value={values.phone}
              onChange=
              {handleInputChange}
            /> */}
              <TextField
                variant="outlined"
                label="Phone"
                name="phone"
                type="phone"
                value={values.phone}
                onChange={handleInputChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            direction="row"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={6} md={6}>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                className={classes.button}
                onClick={() => history.go("/")}
              >
                Start Over
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                disabled={
                  !values.firstName ||
                  !values.lastName ||
                  !values.phone ||
                  !values.email
                }
                className={classes.button}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </div>
  );
}
