import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import GetMarketData from "./GetMarketData";
const useStyles = makeStyles((theme) => ({
  section: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: theme.palette.primary.dark,
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(18),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  description: {
    color: theme.palette.background.secondary,
  },
  primaryAction: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
  secondaryAction: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  titleText: {
    color: "white",
    [theme.breakpoints.down("md")]: {
      fontSize: "4rem",
      lineHeight: "1.1",
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <div>
      <section
        className={classes.section}
        style={{
          backgroundImage: `linear-gradient(#333333c2, #333333c2), url(
          "https://i.imgur.com/d83qJAV.png"
        ) `,
        }}
      >
        <Grid container justify="center" spacing={2}>
          <Grid item xs={10} md={4} lg={4} xl={4}>
            <Typography variant="h1" className={classes.titleText}>
              Sell your vehicle without the hassle.
            </Typography>
          </Grid>
          <Grid item xs={11} md={4} lg={4} xl={3}>
            <GetMarketData />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default Header;
