import React from "react";
import { Grid, Button, Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReviewOffer from "./ReviewOffer";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import { useState } from "react";
import ValueSlider from "./ValueSlider";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    // maxWidth: 660,
  },
  button: {
    height: "60px",
    width: "100%",
  },
  padding: {
    marginTop: "40px",
    padding: "20px",
  },
}));

export default (props) => {
  const classes = useStyles();

  const [showForm, setShowForm] = useState({ value: 0 });
  const [dropDown, setDropDown] = useState({ value: 0 });

  return (
    <div className="container">
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          {props.text.vehicle && (
            <p>
              Estimated cash offer for
              <strong> {props.text.vehicle}</strong>
              <Collapse in={showForm.value === 0 || dropDown.value === 1}>
                Based on vehicle condition:
              </Collapse>
            </p>
          )}

          {showForm.value !== 0 && (
            <IconButton aria-label="expand row" size="large">
              {dropDown.value === 0 ? (
                <KeyboardArrowDownIcon
                  onClick={() => setDropDown({ value: 1 })}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => setDropDown({ value: 0 })}
                />
              )}
            </IconButton>
          )}

          <Collapse in={showForm.value === 0 || dropDown.value === 1}>
            <div className={classes.padding}>
              {props.text.prices.average > 0 &&
              props.text.prices.below !== props.text.prices.above &&
              props.text.prices.below &&
              props.text.prices.average &&
              props.text.prices.above ? (
                <ValueSlider
                  poor={Math.floor(Number(props.text.prices.below))}
                  good={Math.floor(Number(props.text.prices.average))}
                  mint={Math.floor(Number(props.text.prices.above))}
                ></ValueSlider>
              ) : (
                <ValueSlider poor={0} good={500} mint={1000}></ValueSlider>
              )}
            </div>
          </Collapse>

          {showForm.value === 1 && (
            <Grid item xs={12} md={12}>
              <ReviewOffer {...props} setShowForm={setShowForm}></ReviewOffer>
            </Grid>
          )}
        </Grid>
      </Grid>
      {props.formState.status === 0 && showForm.value === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={props.handleRedo}
              className={classes.button}
            >
              Try a different search
            </Button>
          </Grid>
          <Grid item xs={6} md={6}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              onClick={() => setShowForm({ value: 1 })}
            >
              Contact us about offer
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
