import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { make } from "../data/vehicleModels";

export default function AutocompleteFields(props) {
  // const initialValues = {
  //   id: "",
  //   year: "",
  //   make: "",
  //   model: "",
  //   trim: "",
  //   mileage: "",
  //   vehicle: "empty",
  //   vin: "",
  //   success: "",
  //   mean: "",
  //   stdev: "",
  //   count: "",
  //   certainty: "",
  //   period: [],
  //   prices: {},
  //   adjustments: "",
  // };
  // // const { values, setValues, handleInputChange } = useForm(initialValues);
  const [options, setOptions] = useState({ options: [] });
  const [modelval, setModelVal] = useState({ val: "" });
  const [trim, setTrim] = useState({ options: [] });
  const [val, setVal] = useState({ val: "" });
  const [getHelperText, setHelperText] = useState({ value: "" });

  function getCarDetails(make, model) {
    // if (model) {
    //   setModelVal({ val: model });
    // }
    //if the model is cleared. reset the state

    let date = props.selectedDate;
    if (model || modelval.val) {
      let date = 1776;
    }
    fetch(
      `/getAutoCompleteData?&make=${make ? make : val.val}&model=${
        model ? model : ""
      }&year=${props.selectedDate !== 1776 ? props.selectedDate : ""}`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.selections.length === 0) {
          return props.setError({
            text: "We cannot find that vehicle in our database...",
          });
        }
        if (data.selections.length !== 0) {
          props.setError({ text: "" });
        }
        //   data.selections.makes[0].models[0].trims[0].name; console.log('true')
        if (model === undefined) {
          setOptions({
            options: data.selections.years[0].makes[0].models,
          });
        }
        //a huge array of different model objects
        setVal({ val: make });
        //if the trims variable exist but is
        setTrim({
          options: data.selections.years[0].makes[0].models[0].trims
            ? data.selections.years[0].makes[0].models[0].trims
            : [],
        });

        if (
          Object.keys(data.selections.years[0].makes[0].models[0].trims)
            .length === 0
        ) {
          setHelperText({
            value: "The model selected does not have any trim options",
          });
        }
      })
      .catch((error) => {});
  }
  // function getCarDetails(make, model) {
  //   // if (model) {
  //   //   setModelVal({ val: model });
  //   // }
  //   //if the model is cleared. reset the state

  //   let date = props.selectedDate;
  //   if (model || modelval.val) {
  //     let date = 1776;
  //   }
  //   fetch(
  //     `https://specifications.vinaudit.com/v3/selections?format=json&key=VA_DEMO_KEY&make=${
  //       make ? make : val.val
  //     }&model=${model ? model : ""}&year=${
  //       props.selectedDate !== 1776 ? props.selectedDate : ""
  //     }`,
  //     {
  //       method: "GET",
  //     }
  //   )
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.selections.length === 0) {
  //         return props.setError({
  //           text: "We cannot find that vehicle in our database...",
  //         });
  //       }
  //       if (data.selections.length !== 0) {
  //         props.setError({ text: "" });
  //       }
  //       //   data.selections.makes[0].models[0].trims[0].name; console.log('true')
  //       if (model === undefined) {
  //         setOptions({
  //           options: data.selections.years[0].makes[0].models,
  //         });
  //       }
  //       //a huge array of different model objects
  //       setVal({ val: make });
  //       //if the trims variable exist but is
  //       setTrim({
  //         options: data.selections.years[0].makes[0].models[0].trims
  //           ? data.selections.years[0].makes[0].models[0].trims
  //           : [],
  //       });

  //       if (
  //         Object.keys(data.selections.years[0].makes[0].models[0].trims)
  //           .length === 0
  //       ) {
  //         setHelperText({
  //           value: "The model selected does not have any trim options",
  //         });
  //       }
  //     })
  //     .catch((error) => {});
  // }
  // if the model gets cleared and a new make is selected we need to refetch the model request
  return (
    <div>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} md={10}>
          <Autocomplete
            id="Make"
            name="make"
            options={make}
            getOptionLabel={(option) => (option.value ? option.value : "")}
            getOptionDisabled={(option) =>
              option === make[0] || option === make[33]
            }
            disabled={props.selectedDate === 1776}
            onChange={(event, value) => {
              value ? getCarDetails(value.value, undefined) : "";
            }}
            onInputChange={(event, value) => {
              props.setMake({
                value: value,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label="Make" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Autocomplete
            id="Model"
            name="model"
            options={options.options}
            getOptionLabel={(option) =>
              option.name ? option.name.toString() : ""
            }
            getOptionSelected={(option, value) => option.name.toString()}
            disabled={options.options.length <= 0}
            //onchange is getting the auto complete based on the previous car details
            onChange={(event, value) => {
              value ? getCarDetails(undefined, value.name) : "";
            }}
            //oninputchange is setting the state for the fetch call
            onInputChange={(event, value) => {
              props.setModel({
                value: value,
              });
            }}
            // getOptionSelected={(option, value) =>
            //   console.log("option" + option + "value" + value)
            // }
            renderInput={(params) => (
              <TextField {...params} label="Model" variant="outlined" />
            )}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <Autocomplete
            id="Trim"
            name="trim"
            options={trim.options}
            getOptionLabel={(option) =>
              option.name ? option.name.toString() : ""
            }
            disabled={trim.options.length <= 0}
            // onChange={handleInputChange}
            // onInputChange={(event, value) => {
            //   console.log(value);
            // }}
            onInputChange={(event, value) => {
              props.setTrim({
                value: value,
              });
            }}
            helper
            renderInput={(params) => (
              <TextField
                {...params}
                label="Trim"
                variant="outlined"
                helperText={getHelperText.value.toString()}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
