// export const make = [
//   { id: 0, value: "Abarth" },
//   { id: 1, value: "Alfa Romeo" },
//   { id: 2, value: "Aston Martin" },
//   { id: 3, value: "Audi" },
//   { id: 4, value: "Bentley" },
//   { id: 5, value: "BMW" },
//   { id: 6, value: "Bugatti" },
//   { id: 7, value: "Cadillac" },
//   { id: 8, value: "Chevrolet" },
//   { id: 9, value: "Chrysler" },
//   { id: 10, value: "Citroën" },
//   { id: 11, value: "Dacia" },
//   { id: 12, value: "Daewoo" },
//   { id: 13, value: "Daihatsu" },
//   { id: 14, value: "Dodge" },
//   { id: 15, value: "Donkervoort" },
//   { id: 16, value: "DS" },
//   { id: 17, value: "Ferrari" },
//   { id: 18, value: "Fiat" },
//   { id: 19, value: "Fisker" },
//   { id: 20, value: "Ford" },
//   { id: 21, value: "Honda" },
//   { id: 22, value: "Hummer" },
//   { id: 23, value: "Hyundai" },
//   { id: 24, value: "Infiniti" },
//   { id: 25, value: "Iveco" },
//   { id: 26, value: "Jaguar" },
//   { id: 27, value: "Jeep" },
//   { id: 28, value: "Kia" },
//   { id: 29, value: "KTM" },
//   { id: 30, value: "Lada" },
//   { id: 31, value: "Lamborghini" },
//   { id: 32, value: "Lancia" },
//   { id: 33, value: "Land Rover" },
//   { id: 34, value: "Landwind" },
//   { id: 35, value: "Lexus" },
//   { id: 36, value: "Lotus" },
//   { id: 37, value: "Maserati" },
//   { id: 38, value: "Maybach" },
//   { id: 39, value: "Mazda" },
//   { id: 40, value: "McLaren" },
//   { id: 41, value: "Mercedes-Benz" },
//   { id: 42, value: "MG" },
//   { id: 43, value: "Mini" },
//   { id: 44, value: "Mitsubishi" },
//   { id: 45, value: "Morgan" },
//   { id: 46, value: "Nissan" },
//   { id: 47, value: "Opel" },
//   { id: 48, value: "Peugeot" },
//   { id: 49, value: "Porsche" },
//   { id: 50, value: "Renault" },
//   { id: 51, value: "Rolls-Royce" },
//   { id: 52, value: "Rover" },
//   { id: 53, value: "Saab" },
//   { id: 54, value: "Seat" },
//   { id: 55, value: "Skoda" },
//   { id: 56, value: "Smart" },
//   { id: 57, value: "SsangYong" },
//   { id: 58, value: "Subaru" },
//   { id: 59, value: "Suzuki" },
//   { id: 60, value: "Tesla" },
//   { id: 61, value: "Toyota" },
//   { id: 62, value: "Volkswagen" },
//   { id: 63, value: "Volvo" },
// ];
export const make = [
  { id: 0, value: "Popular Makes" },
  { id: 1, value: "Acura" },
  { id: 2, value: "Audi" },
  { id: 3, value: "BMW" },
  { id: 4, value: "Buick" },
  { id: 5, value: "Cadillac" },
  { id: 6, value: "Chevrolet" },
  { id: 7, value: "Chrysler" },
  { id: 8, value: "Dodge" },
  { id: 9, value: "Ferrari" },
  { id: 10, value: "Ford" },
  { id: 11, value: "Genesis" },
  { id: 12, value: "GMC" },
  { id: 13, value: "Honda" },
  { id: 14, value: "Hyundai" },
  { id: 15, value: "INFINITI" },
  { id: 16, value: "Jaguar" },
  { id: 17, value: "Jeep" },
  { id: 18, value: "Kia" },
  { id: 19, value: "Land Rover" },
  { id: 20, value: "Lexus" },
  { id: 21, value: "Lincoln" },
  { id: 22, value: "MAZDA" },
  { id: 23, value: "Mercedes-Benz" },
  { id: 24, value: "MINI" },
  { id: 25, value: "Mitsubishi" },
  { id: 26, value: "Nissan" },
  { id: 27, value: "Porsche" },
  { id: 28, value: "RAM" },
  { id: 29, value: "Subaru" },
  { id: 30, value: "Tesla" },
  { id: 31, value: "Toyota" },
  { id: 32, value: "Volkswagen" },
  { id: 33, value: "All Makes" },
  { id: 34, value: "AMC" },
  { id: 35, value: "Acura" },
  { id: 36, value: "Alfa" },
  { id: 37, value: "Romeo" },
  { id: 38, value: "Aston Martin" },
  { id: 39, value: "Audi" },
  { id: 40, value: "BMW" },
  { id: 41, value: "Bentley" },
  { id: 42, value: "Bugatti" },
  { id: 43, value: "Buick" },
  { id: 44, value: "Cadillac" },
  { id: 45, value: "Chevrolet" },
  { id: 46, value: "Chrysler" },
  { id: 47, value: "Daewoo" },
  { id: 48, value: "Datsun" },
  { id: 49, value: "DeLorean" },
  { id: 50, value: "Dodge" },
  { id: 51, value: "Eagle" },
  { id: 52, value: "FIAT" },
  { id: 53, value: "Ferrari" },
  { id: 54, value: "Fisker" },
  { id: 55, value: "Ford" },
  { id: 56, value: "Freightliner" },
  { id: 57, value: "GMC" },
  { id: 58, value: "Genesis" },
  { id: 59, value: "Geo" },
  { id: 60, value: "HUMMER" },
  { id: 61, value: "Honda" },
  { id: 62, value: "Hyundai" },
  { id: 63, value: "INFINITI" },
  { id: 64, value: "Isuzu" },
  { id: 65, value: "Jaguar" },
  { id: 66, value: "Jeep" },
  { id: 67, value: "Karma" },
  { id: 68, value: "Kia" },
  { id: 69, value: "Lamborghini" },
  { id: 70, value: "Land Rover" },
  { id: 71, value: "Lexus" },
  { id: 72, value: "Lincoln" },
  { id: 73, value: "Lotus" },
  { id: 74, value: "MAZDA" },
  { id: 75, value: "MINI" },
  { id: 76, value: "Maserati" },
  { id: 77, value: "Maybach" },
  { id: 78, value: "McLaren" },
  { id: 79, value: "Mercedes-Benz" },
  { id: 80, value: "Mercury" },
  { id: 81, value: "Mitsubishi" },
  { id: 82, value: "Nissan" },
  { id: 83, value: "Oldsmobile" },
  { id: 84, value: "Plymouth" },
  { id: 85, value: "Polestar" },
  { id: 86, value: "Pontiac" },
  { id: 87, value: "Porsche" },
  { id: 88, value: "RAM" },
  { id: 89, value: "Rolls-Royce" },
  { id: 90, value: "SRT" },
  { id: 91, value: "Saab" },
  { id: 92, value: "Saturn" },
  { id: 93, value: "Scion" },
  { id: 94, value: "Subaru" },
  { id: 95, value: "Suzuki" },
  { id: 96, value: "Tesla" },
  { id: 97, value: "Toyota" },
  { id: 98, value: "Volkswagen" },
  { id: 99, value: "Volvo" },
  { id: 100, value: "Yugo" },
  { id: 101, value: "smart" },
];
