import React from "react";
import {
  Typography,
  Box,
  Grid,
  CardActionArea,
  CardMedia,
  Card,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  switchOrder1: {
    [theme.breakpoints.up("md")]: {
      order: 1,
    },
  },
  switchOrder0: {
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
      order: 0,
    },
  },
  stepContainer: {
    marginBottom: theme.spacing(4),
  },
  media: {
    height: "556px",
  },
}));
export default function LeftImageText() {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={6} className={classes.stepContainer}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardActionArea href="#">
              <CardMedia
                className={classes.media}
                image="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
              />
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" height="100%">
            <Box my="auto">
              <Typography variant="h2" component="h3">
                01
              </Typography>
              <Typography variant="h4" component="h2" gutterBottom={true}>
                Move Data
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                paragraph={true}
              >
                Using our Piper Assistant application, you can move your data to
                be stored our decentralized network with simple drag & drop.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={6} className={classes.stepContainer}>
        <Grid item xs={12} md={6} className={classes.switchOrder1}>
          <Card>
            <CardActionArea href="#">
              <CardMedia
                className={classes.media}
                image="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80"
              />
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className={classes.switchOrder0}>
          <Box display="flex" height="100%">
            <Box my="auto">
              <Typography variant="h2" component="h3">
                02
              </Typography>
              <Typography variant="h4" component="h2" gutterBottom={true}>
                Integrate Software
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                paragraph={true}
              >
                We want to make sure that you can keep using the software that
                you use to manage your business.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
