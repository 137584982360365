import React from "react";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ErrorMessage(props) {
  return (
    <div>
      <Alert severity="error">{props.text}</Alert>
    </div>
  );
}
