import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
export default (props) => {
  const date = new Date();
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          value={
            props.selectedDate.getFullYear() == 1776 ? null : props.selectedDate
          }
          onChange={props.handleDateChange}
          views={["year"]}
          maxDate={date.setFullYear(date.getFullYear() + 1)}
          minDate="1884"
          inputVariant="outlined"
          label="Year"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};
