import React from "react";
import { useForm, Form } from "./UseForm";
import { Grid, TextField, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import MarketData from "./MarketData";
import LinearProgress from "@material-ui/core/LinearProgress";
import YearPicker from "./YearPicker";
import AutoCompleteFields from "./AutoCompleteFields";
import ErrorMessage from "./ErrorMessage";

const initialValues = {
  id: "",
  year: "",
  make: "",
  model: "",
  trim: "",
  mileage: "",
  vehicle: "empty",
  vin: "",
  success: "",
  mean: "",
  stdev: "",
  count: "",
  certainty: "",
  period: [],
  prices: {},
  adjustments: "",
};

const useStyles = makeStyles(() => ({
  button: {
    height: "60px",
    width: "100%",
    textAlign: "center",
  },
}));

export default function CarLookUpForm(props) {
  const { values, setValues, handleInputChange } = useForm(initialValues);
  const [text, setText] = useState(initialValues);
  //set date to 1776 as a default. Default needs to be a date object but it cannot be null. If we leave it null an error will throw. Solution is to check for 1776 then set to null
  const [selectedDate, handleDateChange] = useState(new Date(-6106015800000));
  const [loading, setloading] = useState({ value: 0 });
  const [status, setStatus] = useState({ success: 0 });
  const [formState, updateformState] = useState({ status: 0 });
  const [error, setError] = useState({ text: "" });
  const [getMake, setMake] = useState({ value: "" });
  const [getModel, setModel] = useState({ value: "" });
  const [getTrim, setTrim] = useState({ value: "" });
  console.log(props);
  const classes = useStyles();
  const redo = (e) => {
    updateformState({ status: 1 });
    setValues(initialValues);
    setText(initialValues);
    setStatus({ success: 0 });
    setloading({ value: 0 });
    props.setTurnOffButtons({ value: 0 });
    handleDateChange(new Date(-6106015800000));
  };

  const reset = (e) => {
    if (localStorage.getItem("attempts") >= 5) {
      setError({
        text:
          "You have exceeded the number of cash estimates! Please try again later...",
      });
    } else {
      setError({ text: "" });
      setloading({ value: 1 });
      updateformState({ status: 0 });
    }
  };
  // const submit = (e) => {
  //   e.preventDefault();
  //   setloading({ value: 1 });
  //   if (values.mileage) {
  //     fetch(
  //       `https://marketvalue.vinaudit.com/getmarketvalue.php?key=VA_DEMO_KEY&format=json&period=90&mileage=${
  //         values.mileage
  //       }&year=${selectedDate.getFullYear()}&make=${getMake.value}&model=${
  //         getModel.value
  //       }&trim=${getTrim.value}`,
  //       {
  //         method: "GET",
  //       }
  //     )
  //       .then((response) => {
  //         return response.json();
  //       })
  //       .then((data) => {
  //         if (data.success === false) {
  //           setloading({ value: 0 });
  //           return setError({
  //             text: "Error, No Vehicle Found! Try a different query.",
  //           });
  //         }
  //         setText(data);
  //         setStatus({ success: 1 });
  //         props.setTurnOffButtons({ value: 1 });
  //       })

  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };
  const submit = (e) => {
    e.preventDefault();
    let attempts;
    if (localStorage.getItem("attempts") === null) {
      attempts = localStorage.setItem("attempts", 0);
      console.log(`attemps`, attemps);
    } else {
      attempts = parseInt(localStorage.getItem("attempts"));
      localStorage.setItem("attempts", ++attempts);
      console.log(`attempts`, attempts);
    }

    if (values.mileage && attempts <= 5) {
      fetch(
        `/getVehicleByMake?mileage=${
          values.mileage
        }&year=${selectedDate.getFullYear()}&make=${getMake.value}&model=${
          getModel.value
        }&trim=${getTrim.value}`,
        {
          method: "GET",
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.success === false) {
            setloading({ value: 0 });
            return setError({
              text: "Error, No Vehicle Found! Try a different query.",
            });
          }
          setText(data);
          setStatus({ success: 1 });
          props.setTurnOffButtons({ value: 1 });
        })

        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div>
      {(status.success === 0 || formState.status === 1) && (
        <Form submit={submit}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <YearPicker
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
              ></YearPicker>
            </Grid>
            <Grid item xs={12} md={10}>
              <AutoCompleteFields
                selectedDate={selectedDate.getFullYear()}
                setMake={setMake}
                setModel={setModel}
                setTrim={setTrim}
                setError={setError}
              ></AutoCompleteFields>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                id="standard-number"
                label="Mileage"
                inputProps={{ maxLength: 6 }}
                name="mileage"
                variant="outlined"
                value={values.mileage}
                onChange={handleInputChange}
              />
            </Grid>
            {loading.value === 1 && status.success === 0 && (
              <Grid item xs={12} md={8}>
                <Box m={1} width="100%">
                  <LinearProgress />
                </Box>
              </Grid>
            )}

            {error.text && (
              <Grid item xs={12} md={8}>
                <ErrorMessage text={error.text}></ErrorMessage>{" "}
              </Grid>
            )}

            <Grid item xs={12} md={8}>
              <Button
                style={{ margin: "5px" }}
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                className={classes.button}
                disabled={
                  !values.mileage ||
                  !selectedDate ||
                  !getMake.value ||
                  !getModel.value
                }
                onClick={reset}
              >
                GET INSTANT CASH OFFER!!!
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}

      {status.success === 1 && (
        <MarketData
          text={text}
          formState={formState}
          handleRedo={redo}
        ></MarketData>
      )}
    </div>
  );
}
