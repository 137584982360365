import React from "react";
import { Grid, TextField, Button, Box } from "@material-ui/core";
import { useForm, Form } from "./UseForm";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MarketData from "./MarketData";
import LinearProgress from "@material-ui/core/LinearProgress";
import ErrorMessage from "./ErrorMessage";
const initialValues = {
  vin: "",
  success: "",
  id: "",
  vehicle: "",
  mean: "",
  stdev: "",
  count: "",
  mileage: "",
  certainty: "",
  period: [],
  prices: {},
  adjustments: "",
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: 2,
    },
  },
  button: {
    height: "60px",
    width: "100%",
    textAlign: "center",
  },
}));

export default function VinForm(props) {
  const { values, setValues, handleInputChange } = useForm(initialValues);
  const [text, setText] = useState(initialValues);
  const [formState, updateformState] = useState({ status: 0 });
  const [loading, setloading] = useState({ value: 0 });
  const [status, setStatus] = useState({ success: 0 });
  const [error, setError] = useState({ text: "" });

  const classes = useStyles();

  const redo = (e) => {
    updateformState({ status: 1 });
    setValues(initialValues);
    setText(initialValues);
    setStatus({ success: 0 });
    setloading({ value: 0 });
    props.setTurnOffButtons({ value: 0 });
  };

  const reset = (e) => {
    if (localStorage.getItem("attempts") >= 5) {
      setError({
        text:
          "You have exceeded the number of cash estimates! Please try again later...",
      });
    } else {
      setError({ text: "" });
      setloading({ value: 1 });
      updateformState({ status: 0 });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    let attempts;
    if (localStorage.getItem("attempts") === null) {
      attempts = localStorage.setItem("attempts", 0);
      console.log(`attemps`, attemps);
    } else {
      attempts = parseInt(localStorage.getItem("attempts"));
      localStorage.setItem("attempts", ++attempts);
      console.log(`attempts`, attempts);
    }

    // if (values.vin && attempts < 5) {
    //   fetch(
    //     `https://marketvalue.vinaudit.com/getmarketvalue.php?key=VA_DEMO_KEY&vin=${values.vin}&format=json&period=90&mileage=${values.mileage}`,
    //     {
    //       method: "GET",
    //     }
    //   )
    //     .then((response) => {
    //       return response.json();
    //     })
    //     .then((data) => {
    //       if (data.success === false) {
    //         setloading({ value: 0 });
    //         return setError({
    //           text: "Error, No Vehicle Found! Try a different query.",
    //         });
    //       }

    //       setText(data);
    //       setStatus({ success: 1 });
    //       props.setTurnOffButtons({ value: 1 });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
    if (values.vin && attempts <= 5) {
      fetch(`/getVehicleByVin?vin=${values.vin}&mileage=${values.mileage}`, {
        method: "GET",
      })
        .then((response) => {
          return response.json();
        })

        .then((data) => {
          if (data.success === false) {
            setloading({ value: 0 });
            return setError({
              text: "Error, No Vehicle Found! Try a different query.",
            });
          }

          setText(data);
          setStatus({ success: 1 });
          props.setTurnOffButtons({ value: 1 });
        })
        .catch((error) => {
          console.log("ERROR" + error);
        });
    }
  };

  return (
    <div>
      <div className="container">
        {(status.success === 0 || formState.status === 1) && (
          <Form submit={submit}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <TextField
                  variant="outlined"
                  label="Vin"
                  name="vin"
                  inputProps={{ maxLength: 17 }}
                  value={values.vin}
                  onChange={handleInputChange}
                  autoFocus
                ></TextField>
                <TextField
                  id="standard-number"
                  label="Mileage"
                  name="mileage"
                  variant="outlined"
                  inputProps={{ maxLength: 6 }}
                  value={values.mileage}
                  onChange={handleInputChange}
                />
              </Grid>

              {loading.value === 1 && status.success === 0 && (
                <Grid item xs={12} md={8}>
                  <Box m={1} width="100%">
                    <LinearProgress />
                  </Box>
                </Grid>
              )}

              {error.text && (
                <Grid item xs={12} md={8}>
                  <ErrorMessage text={error.text}></ErrorMessage>{" "}
                </Grid>
              )}

              <Grid item xs={12} md={8}>
                <Button
                  variant="contained"
                  size="large"
                  // style={{ margin: "2px" }}
                  color="primary"
                  type="submit"
                  className={classes.button}
                  disabled={text.vin === values.vin || !values.mileage}
                  onClick={reset}
                >
                  GET INSTANT CASH OFFER!!!
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </div>

      {status.success === 1 && (
        <MarketData
          text={text}
          formState={formState}
          handleRedo={redo}
        ></MarketData>
      )}
    </div>
  );
}
