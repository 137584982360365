import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles({
  root: {
    "&>.MuiSlider-thumb": {
      "&:nth-child(10)": {
        transform: "scale(3) translateY(-1px) !important",
      },
    },

    marginTop: "4vw",
  },
});

const ValueSlider = withStyles({
  root: {
    color: "#green",
    // height: 8,
  },
  thumb: {
    // height: 10,
    // width: 10,

    backgroundColor: "#fff",
    border: "2px solid currentColor",
    // marginTop: -8,
    // marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + -8px)",

    fontSize: "9px",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default function DiscreteSlider(props) {
  const classes = useStyles();
  const marks = [
    {
      value: props.poor,
      label: "Poor",
    },
    {
      value: props.good,
      label: "Good",
    },
    {
      value: props.mint,
      label: "Mint",
    },
  ];

  return (
    <div>
      <ValueSlider
        defaultValue={props.good}
        aria-labelledby="discrete-slider-always"
        step={5}
        min={props.poor}
        max={props.mint}
        marks={marks}
        className={classes.root}
        valueLabelDisplay="on"
      />
    </div>
  );
}
