import React from "react";
import ReactDOM from "react-dom";
import Header from "./components/Header";
import NavBar from "./components/NavBar";
import HowItWorks from "./components/HowItWorks";
import LeftImageText from "./components/LeftImageText";
import Stepper from "./components/Stepper";
import { makeStyles } from "@material-ui/core/styles";

import {
  Container,
  Box,
  Typography,
  Button,
  ArrowRightAltIcon,
} from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  root: {
    width: "calc(100vw - 1px)",
  },
}));

const App = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <NavBar /> */}
      <Header />
      <section>
        <Container maxWidth="md">
          <Box py={20} textAlign="center">
            {/* <Typography variant="overline" component="span">
              How it works{" "}
            </Typography> */}
            <Typography variant="h3" component="h3" color="textSecondary">
              We've taken the hassle out of selling your vehicle
            </Typography>

            <Box mt={4}>
              <Stepper />
            </Box>
          </Box>
        </Container>
      </section>
      {/* <LeftImageText /> */}
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
